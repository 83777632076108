@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    font-size: 62.5%;
}

body {
    min-height: 100vh;
    background-color: #f5f5f5;
}